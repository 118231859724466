html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a:visited {
  color: black;
}

a {
  color: black;
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.tile {
  border: 1px solid black;
}

.tile:nth-child(3n):not(:nth-child(9n)) {
  border-right: 3px solid black;
}

.tile:nth-child(n+19):nth-child(-n+27) {
  border-bottom: 3px solid #000;
}
 
.tile:nth-child(n+46):nth-child(-n+54) {
  border-bottom: 3px solid #000;
}

[tabindex] {
  outline: none;
}

div {
  user-select:none;
}